import React from "react";

class TimelineNote extends React.Component {
  render() {
    const timeframe = this.props.timeframe;
    const content = this.props.content;
    return (
      <div className="timeline-box">
        <time>{timeframe}</time>
        <div
          className="p-padding"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    );
  }
}

export default TimelineNote;
