import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import TimelineBox from "../components/timeline-box";
import TimelineNote from "../components/timeline-note";

class TimelinePage extends React.Component {
  constructor(props) {
    super(props);
    this.callbackFunc = this.callbackFunc.bind(this);
    this.isElementInViewport = this.isElementInViewport.bind(this);
    this.efp = this.efp.bind(this);
  }

  timeline(data) {
    return [
      {
        title: "Showami Native App",
        url: "https://showami.com/",
        timeframe: "December 2019 - January 2020",
        content: "Showami Native App",
        image: data.ShowamiNative.childImageSharp.fluid,
        type: "project",
      },
      {
        title: "Showami",
        url: "https://showami.com/",
        timeframe: "September 2019 - Current",
        content: "Director of Engineering for Showami",
        image: data.Showami2.childImageSharp.fluid,
        type: "project",
      },
      {
        title: "TopoRace",
        url: "https://www.toporace.com",
        timeframe: "June 2019 - Current",
        content: "TopoRace",
        image: data.TopoRace.childImageSharp.fluid,
        type: "project",
      },
      {
        title: "FountainPen.io",
        url: "https://www.fountainpen.io",
        timeframe: "January 2018 - Current",
        content: "FountainPen.io",
        image: data.FountainPen.childImageSharp.fluid,
        type: "project",
      },
      {
        timeframe: "September - November 2018",
        content: `My wife and I took three months off of work to
          <a href='https://www.alexbrinkman.com/europe-2018' target = '_blank'>
          travel with our son </a>, spend time with family, simplify, and get clarity on the
          next phase of our working careers.`,
        type: "note",
      },
      {
        timeframe: "August 2018",
        content: `I am honored to be promoted to VP of Engineering for CKD Technology Partners`,
        type: "note",
      },
      {
        title: "February 2018 - August 2018",
        url: "https://redline.reddotstorage.com/users/sign_in",
        timeframe: "February 2018 - August 2018",
        content: "Redline ERP <small>(A CKD Project)</small>",
        image: data.Redline.childImageSharp.fluid,
        type: "project",
      },
      {
        title: "Mountains Weekly",
        url: "https://www.mountainsweekly.com",
        timeframe: "January 2018 - Current",
        content: "Mountains Weekly",
        image: data.MountainsWeekly.childImageSharp.fluid,
        type: "project",
      },
      {
        title: "Landesa",
        url: "https://ripl.landesa.org/",
        timeframe: "December 2017 - January 2018",
        content: `Landesa <small>(A CKD Project)</small>`,
        image: data.Landesa.childImageSharp.fluid,
        type: "project",
      },
      {
        title: "UTMB",
        url: "https://utvirtualhealthnetwork.com/",
        timeframe: "December 2016 - January 2018",
        content: `University of Texas Virtual Health Network <small>(A CKD Project)</small>`,
        image: data.UTVirtualHealthNetwork.childImageSharp.fluid,
        type: "project",
      },
      {
        title: "Colorado Legal Help Center",
        url: "http://www.coloradolegalhelpcenter.us/",
        timeframe: "July 2016 - December 2016",
        content: `Colorado Legal Help Center <small>(A CKD Project)</small>`,
        image: data.ColoradoLegalHelpCenter.childImageSharp.fluid,
        type: "project",
      },
      {
        title: "Showami",
        url: "https://showami.com/",
        timeframe: "May 2016 - July 2016",
        content: `Showami <small>(A CKD Project)</small>`,
        image: data.Showami.childImageSharp.fluid,
        type: "project",
      },
      {
        title: "LowVolley.com",
        url: "https://lowvolley.com/",
        timeframe: "March 2016 - Current",
        content: `LowVolley`,
        image: data.LowVolley.childImageSharp.fluid,
        type: "project",
      },
      {
        title: "Project Travel",
        url: "https://projecttravel.com/",
        timeframe: "December 2015 - May 2016",
        content: `Project Travel <small>(A CKD Project)</small>`,
        image: data.ProjectTravel.childImageSharp.fluid,
        type: "project",
      },
      {
        title: "Digital Learn",
        url: "https://digitallearn.org/",
        timeframe: "October - December 2015",
        content: `Digital Learn <small>(A CKD Project)</small>`,
        image: data.DigitalLearn.childImageSharp.fluid,
        type: "project",
      },
      {
        title: "Crew Connection",
        url: "https://thecrewcloud.com/",
        timeframe: "February - October 2015",
        content: `Crew Connection <small>(A CKD Project)</small>`,
        image: data.CrewCloud.childImageSharp.fluid,
        type: "project",
      },
      {
        timeframe: "February 2015",
        content: `I joined <a href="http://commercekitchen.com" target="_blank">
          Commerce Kitchen</a> as a Senior Rails Develper`,
        type: "note",
      },
      {
        title: "BooksTo.Me",
        url: "#", //TODO: fix how this ends up working
        timeframe: "October 2014 - October 2015",
        content: `BooksTo.Me <small>(site no longer available)</small>`,
        image: data.BooksToMe.childImageSharp.fluid,
        type: "project",
      },
      {
        timeframe: "March 2014 - April 2015",
        content: `<p>Co-organizer of the Denver Ember.js Meetup Group</p>
          <p>I take pride in the role I played helping the crew at Cirro
          get this meetup get off the ground. I organized speakers for
          about a year, speaking twice myself. The meetup grew from a
          handful of people in 2014 to over fifty by the time I stepped
          down as organizer. The group continues on very strongly today.</p>`,
        type: "note",
      },
      {
        title: "Colorado PERA",
        url: "https://copera.org",
        timeframe: "January 2010 - February 2015",
        content: `Colorado PERA`,
        image: data.PERA.childImageSharp.fluid,
        type: "project",
      },
      {
        timeframe: "January 2010",
        content: `I joined <a href="https://copera.org" target="_blank">
          Colorado PERA</a> as a Java web developer, where I spent four years working in
          Java, and migrating legacy Flex applications over to single
          page applications, first in jQuery, then Ember.js.`,
        type: "note",
      },
    ];
  }

  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="Career Timeline" />
        <h1>Career Timeline</h1>

        <p>
          I put this together as a better way to visualize the projects that I
          have been a part of over the past decade.
        </p>

        <section class="timeline">
          <ul>
            {this.timeline(data).map(node => {
              return <li>{this.display(node)}</li>;
            })}
          </ul>
        </section>
      </Layout>
    );
  }

  display(node) {
    if (node.type === "project") {
      return (
        <TimelineBox
          title={node.title}
          content={node.content}
          url={node.url}
          timeframe={node.timeframe}
          image={node.image}
        />
      );
    } else {
      return <TimelineNote timeframe={node.timeframe} content={node.content} />;
    }
  }

  componentDidMount() {
    // Listen for events
    window.addEventListener("load", this.callbackFunc);
    window.addEventListener("resize", this.callbackFunc);
    window.addEventListener("scroll", this.callbackFunc);
  }

  callbackFunc() {
    var items = document.querySelectorAll(".timeline li");
    for (var i = 0; i < items.length; i++) {
      if (this.isElementInViewport(items[i])) {
        items[i].classList.add("in-view");
      }
    }
  }

  isElementInViewport(el) {
    // Check if an element is in viewport
    // http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
    var rect = el.getBoundingClientRect();
    var vWidth = window.innerWidth; // || doc.documentElement.clientWidth,
    var vHeight = window.innerHeight; // || doc.documentElement.clientHeight,

    // Return false if it's not in the viewport
    if (
      rect.right < 0 ||
      rect.bottom < 0 ||
      rect.left > vWidth ||
      rect.top > vHeight
    ) {
      return false;
    }

    // Return true if any of its four corners are visible
    return (
      el.contains(this.efp(rect.left, rect.top)) ||
      el.contains(this.efp(rect.right, rect.top)) ||
      el.contains(this.efp(rect.right, rect.bottom)) ||
      el.contains(this.efp(rect.left, rect.bottom))
    );
  }

  efp(x, y) {
    return document.elementFromPoint(x, y);
  }
}

export default TimelinePage;

export const pageQuery = graphql`
  query {
    ShowamiNative: file(absolutePath: { regex: "/ShowamiNative.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Showami2: file(absolutePath: { regex: "/Showami2.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TopoRace: file(absolutePath: { regex: "/toporace.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    FountainPen: file(absolutePath: { regex: "/fountainpen.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Redline: file(absolutePath: { regex: "/Redline.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    MountainsWeekly: file(absolutePath: { regex: "/MountainsWeekly.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Landesa: file(absolutePath: { regex: "/Landesa.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    UTVirtualHealthNetwork: file(
      absolutePath: { regex: "/UTVirtualHealthNetwork.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ColoradoLegalHelpCenter: file(
      absolutePath: { regex: "/ColoradoLegalHelpCenter.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Showami: file(absolutePath: { regex: "/Showami.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    LowVolley: file(absolutePath: { regex: "/LowVolley.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ProjectTravel: file(absolutePath: { regex: "/ProjectTravel.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    DigitalLearn: file(absolutePath: { regex: "/DigitalLearn.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    CrewCloud: file(absolutePath: { regex: "/CrewCloud.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    BooksToMe: file(absolutePath: { regex: "/BooksToMe.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    PERA: file(absolutePath: { regex: "/PERA.png/" }) {
      childImageSharp {
        fluid(maxWidth: 370) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
