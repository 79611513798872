import React from "react";
import Image from "gatsby-image";

class TimelineBox extends React.Component {
  render() {
    const title = this.props.title;
    const url = this.props.url;
    const timeframe = this.props.timeframe;
    const image = this.props.image;
    const content = this.props.content;
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className="timeline-box">
          <time>{timeframe}</time>
          {this.displayContent(content)}
          <div style={{ maxWidth: "370px" }}>
            <Image fluid={image} alt={title + " Screenshot"} />
          </div>
        </div>
      </a>
    );
  }

  displayContent(content) {
    if (content) {
      return (
        <div
          className="p-padding"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      );
    }
  }
}

export default TimelineBox;
